import React from "react";
import "./CreateGym.css";
import NavMenu from "./NavMenu";

class CreateSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            AccountDocID: "",
            GYMDocID: "",
            shake: false, // Add shake state
        };
    }

    componentDidMount() {
        var AccountDocID = this.getcookie("AccountDocID");
        this.setState({ AccountDocID: AccountDocID });
    }

    CreateSubscription = () => {
        var GymDocID = this.getcookie("GymDocID");

        var SubScriptionNameIn = document.getElementById("SubscriptionNameIn").value;
        var Cost = document.getElementById("Cost").value;
        var MarginPer = document.getElementById("MarginPer").value;
        var DiscountPer = document.getElementById("DiscountPer").value;
        var TaxPer = document.getElementById("TaxPercentage").value;
        var Price = document.getElementById("Price").value;
        var Days = document.getElementById("Days").value;
        var Months = document.getElementById("Months").value;
        var Details = document.getElementById("Details").value;
        var Image = ""; // document.getElementById("Image").value;

        if (
            SubScriptionNameIn === "" ||
            Cost === "" || isNaN(Number(Cost)) ||
            MarginPer === "" || isNaN(Number(MarginPer)) ||
            DiscountPer === "" || isNaN(Number(DiscountPer)) ||
            TaxPer === "" || isNaN(Number(TaxPer)) ||
            Price === "" || isNaN(Number(Price)) ||
            Days === "" || isNaN(Number(Days)) ||
            Months === "" || isNaN(Number(Months))
        ) {
            this.highlightEmptyFields([
                SubScriptionNameIn, Cost, MarginPer, DiscountPer, TaxPer, Price, Days, Months
            ]);
            this.setState({ shake: true }); // Trigger shake animation
        } else {
            fetch('https://us-central1-carte-gym.cloudfunctions.net/app/CreateSubscription', {
                method: 'POST',
                body: JSON.stringify({
                    "SubscriptionNameIn": SubScriptionNameIn,
                    "Cost": Cost,
                    "MarginPer": MarginPer,
                    "DiscountPer": DiscountPer,
                    "TaxPer": TaxPer,
                    "Price": Price,
                    "Days": Days,
                    "Months": Months,
                    "Details": Details,
                    "Image": Image,
                    "GymDocID": GymDocID
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    document.cookie = "SubscriptionDocID=" + data._path.segments[1];
                    console.log(data);
                    console.log(data._path.segments[1]);

                    this.props.onSubmit();
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }
    }

    highlightEmptyFields = (fields) => {
        const fieldIds = [
            "SubscriptionNameIn", "Cost", "MarginPer", "DiscountPer",
            "TaxPercentage", "Price", "Days", "Months"
        ];
        fields.forEach((field, index) => {
            if (field === "" || isNaN(Number(field))) {
                document.getElementById(fieldIds[index]).classList.add("error");
            } else {
                document.getElementById(fieldIds[index]).classList.remove("error");
            }
        });
    }

    getcookie = (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    DoCalculate = () => {
        var Cost_I = document.getElementById("Cost");
        var Margin_I = document.getElementById("MarginPer");
        var Discount_I = document.getElementById("DiscountPer");
        var Tax_I = document.getElementById("TaxPercentage");
        var Price_I = document.getElementById("Price");

        var Cost = Cost_I.value === "" ? 0 : Number(Cost_I.value);
        var Margin = Margin_I.value === "" ? 0 : Number(Margin_I.value);
        var Discount = Discount_I.value === "" ? 0 : Number(Discount_I.value);
        var Tax = Tax_I.value === "" ? 0 : Number(Tax_I.value);

        Margin = (Margin / 100) * Cost;
        Discount = (Discount / 100) * (Cost + Margin);
        Tax = (Tax / 100) * (Cost + Margin - Discount);
        var Price = Cost + Margin - Discount + Tax;
        Price_I.value = Math.round(Price * 100) / 100;

        document.getElementById("TotalDays").value = Number(Number(document.getElementById("Months").value) * 30 + Number(document.getElementById("Days").value));
    }

    render() {
        return (
            <>
                <div className="FormBG">
                    <div className={`Form ${this.state.shake ? 'shake' : ''}`}>
                        <div className="Header">
                            <h3>New Plan</h3>
                        </div>
                        <div className="FieldsArea">
                            <div className="FieldRow">
                                <b>Plan Name</b>
                                <input name="SubscriptionName" id="SubscriptionNameIn" type="text" placeholder="Subscription Name"></input>
                            </div>
                            <div className="FieldRow">
                                <b>Details</b>
                                <input name="Details" id="Details" type="text" placeholder="Details"></input>
                            </div>
                            <div className="FormFieldDivider"></div>
                            <div className="FieldRow">
                                <b>Cost</b>
                                <input name="Cost" id="Cost" type="number" className="RuppeeSymbol" onChange={this.DoCalculate} placeholder="0"></input>
                            </div>
                            <div className="FieldRow">
                                <b>Margin Percentage</b>
                                <input name="MarginPer" id="MarginPer" type="number" className="PercentSymbol" onChange={this.DoCalculate} placeholder="0"></input>
                            </div>
                            <div className="FieldRow">
                                <b>Discount Percentage</b>
                                <input name="DiscountPer" id="DiscountPer" type="number" className="PercentSymbol" onChange={this.DoCalculate} placeholder="0"></input>
                            </div>
                            <div className="FieldRow">
                                <b>Tax Percentage</b>
                                <input name="TaxPercentage" id="TaxPercentage" type="number" className="PercentSymbol" onChange={this.DoCalculate} placeholder="0"></input>
                            </div>
                            <div className="FieldRow">
                                <b>Price</b>
                                <input name="Price" id="Price" readOnly="true" className="RuppeeSymbol" type="number" placeholder="0"></input>
                            </div>
                            <div className="FormFieldDivider"></div>
                            <div className="FieldRow">
                                <b>Months</b>
                                <input name="Months" id="Months" type="Number" placeholder="0" onChange={this.DoCalculate}></input>
                            </div>
                            <div className="FieldRow">
                                <b>Days</b>
                                <input name="Days" id="Days" type="Number" placeholder="0" onChange={this.DoCalculate}></input>
                            </div>
                            <div className="FieldRow">
                                <b>Total Days</b>
                                <input name="TotalDays" id="TotalDays" type="Number" readOnly="true" placeholder="0"></input>
                            </div>
                            <div className="ButtonArea">
                                <input type="button" value="Create Subscription" onClick={this.CreateSubscription}></input>
                                <input type="button" value="Cancel" onClick={this.props.onSubmit}></input>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CreateSubscription;
